import { P } from '../../utilities/auth/permissions';
import { FinanceWidget } from '../../components/widgets/finance_widget';
import { HelpSupportWidgetContainer } from '../../components/widgets/help_and_support_widget';
import { NotificationsWidgetContainer } from '../../components/widgets/notifications_widget';
import { CrmWidgetContainer } from '../../components/widgets/crm_widget';
import { ToolsWidgetContainer } from '../../components/widgets/tools_widget';
import { ActivityWidgetContainer } from '../../components/widgets/activity_widget';
import { PerformanceWidget } from '../../components/widgets/performance_widget';
import { ActionPermission } from '../../utilities/auth/permissions';
import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { jwt_auth } from '../../utilities/auth';
import { SvgIcon } from '../../components/svgs/svg_icon';
import { Widget } from '../../components/widgets/base_widget';
import { ComponentType, Placeholder, RiseCard } from '@hmkts/rise';
import { PodcastsWidgetContainer } from '../../components/widgets/podcast_widget';
import { isMobileDevice } from '../../utilities/is_mobile';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { ConnectedComponent } from 'react-redux';
import { isAgentRole } from '../../utilities/agent_util';
import { Strings } from '../../assets/common/strings';
import { LicenseAppointmentWidgetContainer } from '../../components/widgets/license_and_appointment_widget';
import { AppConfig } from '../../types/config';

interface Widget {
    risePlaceholderId?: string;
    brokerage: boolean;
    permission: ActionPermission;
    additionalPermission?: () => boolean;
    title: string;
    iconTitle: string;
    component: ComponentType | ConnectedComponent<any, any>;
    overrideKey?: string;
    isVisible?: boolean
}

let allWidgets: Widget[] = [
    {
        brokerage: true,
        permission: P.TodoList,
        title: 'To-Do List',
        iconTitle: 'activities',
        component: ActivityWidgetContainer,
    },
    {
        brokerage: true,
        permission: P.Lead,
        title: 'CRM',
        iconTitle: 'crm',
        component: CrmWidgetContainer,
        overrideKey: 'leadDashboard',
    },
    {
        brokerage: true,
        permission: P.Notification,
        title: 'Notifications',
        iconTitle: 'notification',
        component: NotificationsWidgetContainer,
    },
    {
        brokerage: false,
        permission: P.LicenseAppointment,
        title: 'Licenses & Appointments',
        iconTitle: 'licenseAppointment',
        component: LicenseAppointmentWidgetContainer,
        isVisible: AppConfig.is_license_appointment_feature_flag_enabled
    },
    {
        brokerage: false,
        permission: P.Performance,
        title: 'Performance',
        iconTitle: 'performance',
        component: PerformanceWidget,
    },
    {
        risePlaceholderId: "DesktopHomeWidget5",
        brokerage: false,
        permission: P.FactFinder,
        title: 'Fact Finder',
        iconTitle: 'factfinder',
        component: RiseCard
    },
    {
        brokerage: false,
        permission: P.PodcastRead,
        title: 'Podcasts',
        iconTitle: 'podcasts',
        component: PodcastsWidgetContainer,
    },
    {
        brokerage: false,
        permission: P.FinanceCashCard,
        additionalPermission: () => !isAgentRole([Strings.ADRoles.Assistant, Strings.ADRoles.SubAgent]),
        title: 'Finances',
        iconTitle: 'finance',
        component: FinanceWidget,
    },
    {
        brokerage: true,
        permission: P.Tool,
        title: 'Tools',
        iconTitle: 'tools',
        component: ToolsWidgetContainer,
    },
    {
        brokerage: true,
        permission: P.Help,
        title: 'Help & Support',
        iconTitle: 'help',
        component: HelpSupportWidgetContainer,
    },
];

export const AcWidgets: React.FC = () => {

    allWidgets = allWidgets.filter(widget => (widget.isVisible == true || widget.isVisible == undefined));

    const visibleWidgets = isBrokerage
        ? allWidgets.filter(widget => widget.brokerage)
        : allWidgets;

    return (
        <div style={{ margin: 10 }}>
            <Grid container spacing={1}>
                {visibleWidgets.map(widget => {
                    let granted = jwt_auth.hasPermission(widget.permission);
                    if (widget.additionalPermission !== undefined && granted) {
                        granted = widget.additionalPermission();
                    }
                    const key = `home-widget-${widget.title}`;
                    if (granted) {
                        return (
                            <Grid key={key} item xs={12} sm={12} md={6} lg={4}>
                                {widget.risePlaceholderId && !isMobileDevice
                                    ? <Placeholder placeholderId={widget.risePlaceholderId}/>
                                    : <widget.component />
                                }
                            </Grid>
                        );
                    }
                    return (
                        <Grid key={key} item xs={12} sm={12} md={6} lg={4}>
                            <AcWidgetPlaceholder
                                key={key}
                                title={widget.title}
                                iconTitle={widget.iconTitle}
                                permission={granted}
                            />
                        </Grid>
                    );
                })}

            </Grid>
        </div>
    );
};

const activeWidget = (widget: string) => {
    switch (widget) {
        case 'performance':
        case 'activities':
        case 'crm':
        case 'notification':
        case 'battles':
        case 'finance':
        case 'tools':
        case 'help':
        case 'factfinder':
            return true;
    }
};
interface AcWidgetPlaceholderProps {
    title: string;
    iconTitle: string;
    permission: boolean;
}
const AcWidgetPlaceholder: React.FC<AcWidgetPlaceholderProps> = ({
    title,
    iconTitle,
    permission,
}) => {
    const noPermission = !permission;
    const comingSoon = !activeWidget(iconTitle);
    const disabled = noPermission || comingSoon;
    return (
        <Widget
            title={title}
            avatar={<SvgIcon type={iconTitle} />}
            disabled
            actionButtons={permission && activeWidget(iconTitle)
                ? [<Button disabled>View All</Button>]
                : comingSoon
                    ? [<Button disabled={true}>Coming Soon</Button>]
                    : [<Button disabled>{''}</Button>]
            }
        >
            <Grid container justify="center">
                <Grid item>
                    <div style={{ display: 'inline-block', height: '200px' }}>
                        <SvgIcon
                            type={iconTitle}
                            size={200}
                            style={{
                                opacity: disabled ? 0.3 : 1,
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Widget>
    );
};