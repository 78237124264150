import moment from 'moment';
import _ from 'lodash';
import { HouseholdRole } from '../reducers/ContactReducer';
import { Lead, LeadHistory } from '../reducers/LeadReducer';
import { Contact, Address } from '../reducers/ContactReducer';
import { EMPTY_CONTACT } from './empty_entities';
import { initialFilterState, LeadFilters } from '../reducers/LeadFilterReducer';
import { Lookup, LookupDictionary, DefaultDictionary, Lookups } from './lookup';
import { GetFilteredLeadsPayload } from '../actions/lead_actions';
import { Loaded } from '../utilities/utilities';
import { Activity } from '../reducers/activity_reducer';
import {
	getPrimaryContactOnLead,
	getAgeFromDate,
	displayGenderAndAge,
} from './utilities';
import { fullName } from '../assets/common/string_builders';
import { Strings } from '../assets/common/strings';
import { composeNameGenderDobAge } from './contact_util';
export function renderLeadCount(leadCount?: number) {
	if (!leadCount) {
		return '0';
	}
	const leadCountString = leadCount.toString();

	if (leadCount > 99000) {
		return '99k+';
	}
	if (leadCount > 999) {
		const abbreviationCount = leadCountString.length - 3;
		return leadCountString.substring(0, abbreviationCount) + 'k';
	}

	return leadCountString;
}

export function getLeadsWithHistoryAsActivities(
	leads: Loaded<Lead>[],
	lookups: LookupDictionary
): Loaded<Activity>[] {
	let historicalLeads: Loaded<Activity>[] = [];
	let contact: Contact;
	let description: string;

	leads.forEach((lead: Loaded<Lead>) => {
		if (lead.data && lead.data.history) {
			lead.data.history.forEach(
				(leadHistory: LeadHistory, historyIndex: number) => {
					let nextStatus: string = '';

					if (lead.data.history && lead.data.history[historyIndex + 1]) {
						nextStatus = lead.data.history[historyIndex + 1].statusBeforeUpdate;
					} else {
						nextStatus = lead.data.status;
					}

					const fromStatus = lookups.find(
						leadHistory.statusBeforeUpdate
					);
					const fromStatusCode = fromStatus.getFirstParentOfType(
						Lookups.LeadStatusCode
					);
					const fromStatusCodeLabel = fromStatusCode.matches(Lookups.NoSale)
						? fromStatusCode.label.concat(' - ')
						: '';
					const fromDisplayStatus = fromStatusCodeLabel + fromStatus.label;

					const toStatus = lookups.find(nextStatus);
					const toStatusCode = toStatus.getFirstParentOfType(
						Lookups.LeadStatusCode
					);
					const toStatusCodeLabel = toStatusCode.matches(Lookups.NoSale)
						? toStatusCode.label.concat(' - ')
						: '';
					const toDisplayStatus = toStatusCodeLabel + toStatus.label;

					contact = (lead.data.contacts.find(
						(contact: Contact) => contact.householdRole == HouseholdRole.Primary
					) || undefined) as Contact;
					description =
						'From "' + fromDisplayStatus + '" to "' + toDisplayStatus + '"';

					const historicalLead: Activity = {
						contact: contact,
						title: Strings.Activity.LeadStatusChange,
						description: description,
						alternatePhoneNumber: '',
						appointmentType: Strings.Activity.LeadStatusChange,
						createdBy: lead.data.createdBy,
						createdByName: lead.data.createdByName,
						createdOn: lead.data.createdOn,
						completedOn: new Date(leadHistory.updatedOn),
						googleEventId: '',
						id: lead.data.id + '_' + leadHistory.updatedOn,
						isDeleted: false,
						isHighPriority: false,
						location: '',
						modifiedBy: lead.data.updatedBy,
						modifiedOn: new Date(leadHistory.updatedOn),
						sender: '',
						status: Strings.ActivityStatus.Completed,
						time: new Date(leadHistory.updatedOn),
						toRecipients: '',
						type: Strings.Activity.LeadStatusChange,
						updatedBy: lead.data.updatedBy,
						updatedOn: new Date(leadHistory.updatedOn),
						userId: lead.data.userId,
						lead: lead.data.id,
						householdId: lead.data.householdId,
						twilioSid: '',
						clickToCallDisposition: '',
						clickToCallSessionId: undefined
					};

					const loadedActivity: Loaded<Activity> = {
						data: historicalLead,
						errors: [],
						loading: false,
					};

					historicalLeads.push(loadedActivity);
				}
			);
		}
	});

	return historicalLeads;
}

export function formatLeadFiltersToStore(status: Lookup, filterIsOpen = false): LeadFilters {
	const sortBy = Strings.SortingOrder.Newest_Created
	return {
		...initialFilterState,
		statusGroupFilter: status.isChildOf(Lookups.DerivedLeadStatus)
			? status
			: Lookups.All,
		statusFilter: status.isChildOf(Lookups.DerivedLeadStatus)
			? Lookups.All
			: status,
		sortBy: sortBy,
		filterIsOpen,
	};
}

export const formatLeadFiltersPayload = (
	leadFilters: LeadFilters,
	pageNum: number,
	pageSize: number = 20
): GetFilteredLeadsPayload => {
	const { statusGroupFilter, statusFilter, ...filters } = leadFilters;
	if (
		statusGroupFilter.isLookup(Lookups.All) &&
		statusFilter.isLookup(Lookups.All)
	) {
		return {
			...filters,
			statusFilterLabels: [],
			pageNum,
			pageSize,
		};
	}
	const statusChildren =
		(statusGroupFilter.isChildOf(Lookups.DerivedLeadStatus) &&
			statusFilter.isLookup(Lookups.All)) ||
			statusFilter.isChildOf(Lookups.DerivedLeadStatus)
			? statusGroupFilter.getChildren()
			: statusFilter.isChildOf(Lookups.DerivedLeadStatus) ||
				statusFilter.matches(Lookups.NoSale)
				? statusFilter.getChildren()
				: [statusFilter];
	const statusFilterLabels =
		statusChildren.length > 0
			? statusChildren.map(child => child.label)
			: [statusFilter.label];
	return {
		...filters,
		statusFilterLabels,
		pageNum,
		pageSize,
	};
};

export const getBulkReassignFilter = (url: string) => {
	let [newUrl, dates] = url.split('?start=');
	newUrl = newUrl || '/lead/list';

	let [start, end] = dates.split('&end=');
	const startDate = moment(start).isValid()
		? moment
			.utc(start)
			.local()
			.startOf('d')
			.toDate()
		: undefined;
	const endDate = moment(end).isValid()
		? moment
			.utc(end)
			.local()
			.endOf('d')
			.toDate()
		: undefined;
	return {
		...initialFilterState,
		statusFilter: Lookups.All,
		dateTypeFilter: 'Assigned date',
		toDate: endDate,
		fromDate: startDate,
		sortBy: Strings.SortingOrder.Newest_Created,
	};
}

export const getLeadStatusReasonLabels = (
	status: Lookup | string,
	lookups: LookupDictionary
): string[] => {
	const lookup = typeof status == 'string' ? lookups.find(status) : status;
	if (lookup.matches(Lookups.Sale)) {
		return [Lookups.Sale.label];
	} else if (lookup.matches(Lookups.Complete)) {
		return lookups.getLabels([status, Lookups.LeadStatus], Lookups.NoSale);
	} else {
		return lookups.getLabels([status, Lookups.LeadStatus], Lookups.NoSale);
	}
};

export const hydrateLeads = (leads: Lead[]) => {
	leads.forEach(lead => {
		lead.contacts.forEach(contact => {
			contact.employerId =
				lead.employer && lead.employer.id
					? lead.employer.id
					: contact.employerId;
		});

		if (lead.nextActivity) {
			lead.nextActivity.contact =
				lead.contacts.find(
					contact => contact.householdRole == HouseholdRole.Primary
				) || EMPTY_CONTACT;
		}
	});
};

export function extractLeadInfo(lead: Lead) {
	let contact = lead && lead.contacts && lead.contacts.length > 0
		? getPrimaryContactOnLead(lead)
		: ({} as Contact);

	const contacts = lead && lead.contacts ? lead.contacts : [];


	let preferredAddress: Address = {} as Address;

	if (contact.addresses) {
		const addressIndex = _.findIndex(contact.addresses, address => {
			return address.isPreferred;
		});
		if (addressIndex > -1) {
			preferredAddress = contact.addresses[addressIndex];
		} else if (contact.addresses.length > 0) {
			preferredAddress = contact.addresses[0];
		} else {
			preferredAddress = {} as Address;
		}
	}

	const address1 = preferredAddress.line1 || '';
	const address2 = preferredAddress.line2 || '';

	const zipCode = preferredAddress.zipCode || '';
	const city = preferredAddress.city || '';
	const state = preferredAddress.state || '';
	const address3 =
		city && state && zipCode ? `${city}, ${state} ${zipCode}` : '';
	const fullAddress = `${address1} ${address2} ${address3}`;

	const linesOfBusiness = lead.linesOfBusiness || [];
	const status = DefaultDictionary.find(lead.status);
	const statusGroup = status.getFirstParentOfType(Lookups.DerivedLeadStatus);
	const companyName = lead && lead.employer ? lead.employer.companyName : '';

	const contactNameGenderDobAge = composeNameGenderDobAge(contact);
	const headerName =
		companyName || contactNameGenderDobAge;

	return {
		contact,
		contacts,
		fullAddress,
		linesOfBusiness,
		status,
		statusGroup,
		headerName,
	};
}

export function leadIdIsEmpty(leadId?: string | null): leadId is null | undefined {
	if (leadId) {
		const strippedId = leadId.replace(/0/g, '').replace(/-/g, '');
		return strippedId.length === 0;
	} else {
		return true;
	}
}

export const isCompanyLead = (lead: Lead) =>
	lead && lead.inputSource &&
	(
		lead.inputSource == 'LeadConnect' ||
		lead.inputSource == 'Live Transfer' ||
		lead.inputSource == 'Scheduler'
	);