import {
	Avatar,
	Badge,
	Grid,
	Hidden,
	IconButton,
} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import {
	FilterOptions,
	GetPagedActivities,
} from '../../actions/activity_actions';
import {
	todayOpenActivityParams,
} from '../../components/widgets/activity_widget';
import {
	getClientConnectInfo, openClientConnectConfigureDialog,
} from '../../actions/client_connect_actions';
import { GetNotContactedLeadCount } from '../../actions/lead_actions';
import {
	navigateTo,
} from '../../actions/navigation_actions';
import { GetAllNotifications } from '../../actions/notification_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { LaunchIcon, ILaunchIconProps } from '../../components/Home/LaunchIcon';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import { jwt_auth } from '../../utilities/auth';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { Notification } from '../notifications_page/notifications_page';
import { IconType } from '../../components/svgs/svg_icon';
import { themePalette, themeImages, themeLinks } from '../../utilities/branding';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { P } from '../../utilities/auth/permissions';
import { AcWidgets } from './ac_widgets';
import { connect, Placeholder } from '@hmkts/rise';
import { isDesktop, isTablet } from '../../utilities/is_mobile';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { getTodaysActivities } from '../../selectors/activity_selectors';
import { getNewNotifications, getUrgentNotifications } from '../../selectors/notifications_selectors';
import { isAgentRole } from '../../utilities/agent_util';
import { Strings } from '../../assets/common/strings';
import { useLinkToExternal, useNavigateTo } from '../../utilities/hooks';
import { SimpleListItem } from '../../components/utility/simple_list_item';

import bellIcon from '../../assets/home/AgentConnectIcons_35_notifications_white.svg';
import { AppConfig } from '../../types/config';
interface StateProps {
	agentCode: string;
	userID: string;
	impersonatingID: string;
	preferredName: string;
	firstName: string;
	notContactedLeadsCount: number;
	activitiesCount: number;
	newNotifications: Notification[];
	urgentNotifications: Notification[];
	clientConnectIsActivated: boolean;
	clientConnectNewActivity: number;
	isLoading:boolean;
}
interface DispatchProps {
	getNotContactedLeadsCount: () => void;
	navigateTo: (route: string) => void;
	fetchAllNotifications: (userID: string) => void;
	getPagedActivities: (options: FilterOptions, clearCache?: boolean) => void;
	getClientConnectInfo: () => void;
	openClientConnect: () => void;
}
type Props = StateProps & DispatchProps & NavigationProps;

const HomePage: React.FC<Props> = (props) => {

	const {
		preferredName, firstName, newNotifications = [], activitiesCount,
		impersonatingID, userID, urgentNotifications, notContactedLeadsCount,
		getNotContactedLeadsCount, getClientConnectInfo, getPagedActivities, fetchAllNotifications,
		clientConnectNewActivity, openClientConnect,isLoading
	} = props;

	useEffect(() => {
		if (jwt_auth.hasPermission(P.Lead)) {
			getNotContactedLeadsCount();
		}
		if (jwt_auth.hasPermission(P.ClientConnect)) {
			getClientConnectInfo();
		}
		if (jwt_auth.hasPermission(P.TodoList) && !isDesktop()) {
			getPagedActivities(todayOpenActivityParams);
		}

		const userId = impersonatingID || userID;
		if (jwt_auth.hasPermission(P.Notification) && userId && jwt_auth.checkUser(userId)) {
			fetchAllNotifications(userId);
		}
	}, []);

	const showUrgent = useNavigateTo(navRoutes.urgentNotifications.path);
	const navToLeads = useNavigateTo(navRoutes.leadCircle.path);
	const navToExternalLeads = useLinkToExternal(AppConfig.lead_connect_link);
	const openLeadConnect = jwt_auth.hasPermission(P.Lead)
		? navToLeads
		: navToExternalLeads;

	const onClickQuote = useLinkToExternal(isBrokerage ? themeLinks.quoteConnect : AppConfig.agentconnect_ecom_link);

	const navToDocuments = useNavigateTo(navRoutes.documents.path);
	const navToExternalDocuments = useLinkToExternal(AppConfig.excelsior_broker_docs_link);
	const onClickDocuments = isBrokerage 
		? navToExternalDocuments
		: navToDocuments;
	const navToNotifications = useNavigateTo(navRoutes.notificationList.path);
	const navToActivities = useNavigateTo(navRoutes.activities.path);
	const navToLeadDashboard = useNavigateTo(navRoutes.leadDashboard.path);
	const navToPerformance = useNavigateTo(navRoutes.performance.path);
	const navToPodcasts = useNavigateTo(navRoutes.podcastList?.path);
	const navToFinance = useNavigateTo(navRoutes.finance.path);
	const navToTools = useNavigateTo(navRoutes.tools.path);
	const navToLicenseAppointment = useNavigateTo(navRoutes.license.path);

	const renderUrgentNotifications = () => {
		const urgentNotificationCount = urgentNotifications.length;
		if (urgentNotificationCount > 0) {
			const urgentNotificationHint = urgentNotificationCount > 1
				? `...and ${urgentNotificationCount - 1} more`
				: '';

			const lastUrgentNotification: Notification = urgentNotifications[0];
			const urgentNotificationTitle = lastUrgentNotification?.title || '';
			let urgentNotificationContent = lastUrgentNotification?.content?.base || '';
			if (urgentNotificationHint.length > 0) {
				urgentNotificationContent =
					urgentNotificationContent?.substring(
						0,
						window.innerWidth / 10 - urgentNotificationHint.length - 4
					) + urgentNotificationHint;
			} else {
				urgentNotificationContent = urgentNotificationContent?.substring(
					0,
					window.innerWidth / 10
				);
			}
			return (
				<SimpleListItem
					icon={
						<Avatar style={{ backgroundColor: themePalette.urgent_avatar }}>
							{urgentNotificationCount}
						</Avatar>
					}
					title={urgentNotificationTitle}
					subtitle={urgentNotificationContent}
					style={{ backgroundColor: themePalette.secondary_background }}
					onClick={showUrgent}
					divider={false}
				/>
			);
		}
	};

	const renderBubbles = () => {
		type HomeBubble = ILaunchIconProps & {
			key: string;
			brokerage: number;
			risePlaceholderId?: string;
			isVisible?: boolean
		};
		const allBubbles = useMemo<HomeBubble[]>(() => [
			{
				key: 'activities',
				brokerage: 3,
				itemCount: activitiesCount,
				icon: IconType.Activities,
				color: themePalette.menuSvgActivities,
				caption: 'To-Do List',
				iconClick: navToActivities,
				isClickable: jwt_auth.hasPermission(P.TodoList),
			},
			{
				key: 'leads',
				brokerage: 1,
				itemCount: notContactedLeadsCount,
				icon: IconType.Crm,
				color: themePalette.menuSvgCRM,
				caption: 'CRM',
				iconClick: navToLeadDashboard,
				isClickable: jwt_auth.hasPermission(P.Lead),
			},
			{
				key: 'quote',
				brokerage: 2,
				itemCount: 0,
				icon: IconType.Quote,
				color: themePalette.menuSvgQuotes,
				caption: 'QuoteConnect',
				iconClick: onClickQuote,
				isClickable: jwt_auth.hasPermission(P.Quote),
			},
			{
				key: 'licenseAppointment',
				brokerage: -1,
				itemCount: 0,
				icon: IconType.LicenseAppointment,
				color: themePalette.menuSvgLicenseAppointment,
				caption: 'Licenses & Appointments',
				iconClick: navToLicenseAppointment,
				isClickable: jwt_auth.hasPermission(P.LicenseAppointment),
				isVisible: AppConfig.is_license_appointment_feature_flag_enabled
			},
			{
				key: 'performance',
				brokerage: -1,
				itemCount: 0,
				icon: IconType.Performance,
				color: themePalette.menuSvgPerformance,
				caption: 'Performance',
				iconClick: navToPerformance,
				isClickable: jwt_auth.hasPermission(P.Performance),
			},
			{
				key: 'factfinder',
				brokerage: -1,
				itemCount: 0,
				icon: IconType.FactFinder,
				color: themePalette.menuSvgBattles,
				caption: 'Fact Finder',
				isClickable: (isDesktop() || isTablet()) && jwt_auth.hasPermission(P.FactFinder),
				risePlaceholderId: "MobileHomeWidget5"
			},
			{
				key: 'podcasts',
				brokerage: -1,
				itemCount: 0,
				icon: IconType.Podcast,
				color: themePalette.menuSvgPodcast,
				caption: 'Podcasts',
				iconClick: navToPodcasts,
				isClickable: jwt_auth.hasPermission(P.PodcastRead),
			},
			{
				key: 'leadConnect',
				brokerage: 0,
				itemCount: 0,
				icon: IconType.LeadConfig,
				color: themePalette.menuSvgLeads,
				caption: 'Leads',
				iconClick: openLeadConnect,
				isClickable: jwt_auth.hasPermission(P.LeadConnectRead),
			},
			{
				key: 'finances',
				brokerage: -1,
				itemCount: 0,
				icon: IconType.Finance,
				color: themePalette.menuSvgFinance,
				caption: 'Finances',
				iconClick: navToFinance,
				isClickable: jwt_auth.hasPermission(P.Finance) && !isAgentRole([Strings.ADRoles.Assistant, Strings.ADRoles.SubAgent]),
			},
			{
				key: 'documents',
				brokerage: 5,
				itemCount: 0,
				icon: IconType.Documents,
				color: themePalette.menuSvgDocuments,
				caption: 'Documents',
				iconClick: onClickDocuments,
				isClickable: jwt_auth.hasPermission(P.Document),
			},
			{
				key: 'tools',
				brokerage: 4,
				itemCount: 0,
				icon: IconType.Tools,
				color: themePalette.menuSvgTools,
				caption: 'Tools',
				iconClick: navToTools,
				isClickable: jwt_auth.hasPermission(P.Tool),
			},
			{
				key: 'clientConnect',
				brokerage: -1,
				itemCount: clientConnectNewActivity ? '!' : 0,
				icon: IconType.ClientConnect,
				color: themePalette.menuSvgClientConnect,
				caption: 'ClientConnect',
				iconClick: openClientConnect,
				isClickable: jwt_auth.hasPermission(P.ClientConnect),
			},
		], [activitiesCount, notContactedLeadsCount]);

		let visibleBubbles = allBubbles;

		visibleBubbles = visibleBubbles.filter(bubble => (bubble.isVisible == true || bubble.isVisible == undefined));

		if (isBrokerage) {
			visibleBubbles = visibleBubbles.filter(bubble => bubble.brokerage >= 0).sort((a, b) => a.brokerage - b.brokerage);
		}

		const chunk = 3;
		const bubbleChunks = visibleBubbles.reduce((result, value, index) => {
			if (index % chunk === 0) {
				result.push(visibleBubbles.slice(index, index + chunk));
			}
			return result;
		}, [] as HomeBubble[][]);
		return (
			<Grid container style={{
				paddingTop: 20,
				paddingBottom: 20,
				backgroundColor: themePalette.default_background,
			}}>
				{bubbleChunks.map((bubbleGroup, index) => (
					<Grid key={`bubble-group-${index}`} container item alignContent='center'>
						{bubbleGroup.map(bubble => {
							const { key, ...rest } = bubble;
							const style = bubble.isClickable ? styles.tile : styles.disabled;
							return (
								<Grid key={key} item xs={4}>
									<div style={style}>
										{bubble.risePlaceholderId
											? <Placeholder
												placeholderId={bubble.risePlaceholderId}
											/>
											: <LaunchIcon {...rest} />
										}

									</div>
								</Grid>
							);
						})}
					</Grid>
				))}
			</Grid>
		);
	};

	const displayName = preferredName || firstName;
	const newNotificationsCount = newNotifications.length;
    
	const menuItems: JSX.Element[] = [];
	if (!isDesktop()) {
		menuItems.push(
			<IconButton key="notification-bell" onClick={navToNotifications}>
				<Badge badgeContent={newNotificationsCount} color="error">
					<img src={bellIcon} width={27} height={27} />
				</Badge>
			</IconButton>
		);
	}
	menuItems.push(
		<MoreMenu
			key={'menu-home'}
			children={[createHelpAction(themeLinks.helpLinkHome)]}
		/>
	);

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					hideBackButton={true}
					rightButtons={menuItems}
					isLoading={isLoading}
					customContent={
						<Grid container style={{ marginTop: isDesktop() ? -34 : -20 }} direction="column" justify="center" alignItems="center">
							<Grid item style={{ fontSize: '20pt', color: themePalette.negative_text }}>
								{`Hello${displayName ? ` ${displayName}!` : '!'}`}
							</Grid>
							<Grid item container style={{ paddingLeft: 20, paddingBottom: 10 }}>
								<img src={themeImages.logo} height={26}/>
							</Grid>
						</Grid>
					}
				/>
			}
		>
			<PersistentNotifications />
			{renderUrgentNotifications()}
			<Hidden smDown>
				<AcWidgets />
			</Hidden>
			<Hidden mdUp>
				{renderBubbles()}
			</Hidden>
		</BasePageContainer>
	);

}

const mapStateToProps = (state: AppState): StateProps => ({
	agentCode: state.user.agentID,
	userID: state.user.id,
	impersonatingID: state.user.impersonatingId,
	firstName: state.agent.firstName,
	preferredName: state.agent.preferredName,
	notContactedLeadsCount: state.lead.statusCounts['Not Contacted'] || 0,
	activitiesCount: getTodaysActivities(state).length,
	newNotifications: getNewNotifications(state),
	urgentNotifications: getUrgentNotifications(state),
	clientConnectIsActivated: state.clientConnect.settings.isActivated,
	clientConnectNewActivity: state.clientConnect.newActivity,
	isLoading:state.agent.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	navigateTo: (route: string) => dispatch(navigateTo(route)),
	getNotContactedLeadsCount: () => dispatch(GetNotContactedLeadCount.started(undefined)),
	fetchAllNotifications: (userID: string) => dispatch(GetAllNotifications.started(userID)),
	getClientConnectInfo: () => dispatch(getClientConnectInfo.started()),
	getPagedActivities: (options: FilterOptions, clearCache: boolean) =>
		dispatch(
			GetPagedActivities.started({
				filters: options,
				pageNum: 0,
				pageSize: 100,
				skipPageIncrease: true,
				clearCache,
			})
		),
	openClientConnect: () => dispatch(openClientConnectConfigureDialog.started()),
});

export const HomePageContainer = connect(mapStateToProps, mapDispatchToProps, true)(HomePage);

const styles: { [key: string]: React.CSSProperties } = {
	tile: {
		textAlign: 'center',
	},
	disabled: {
		opacity: 0.3,
		textAlign: 'center',
		pointerEvents: 'none',
	},
};
