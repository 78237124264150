import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { themePalette } from '../utilities/branding';
import { Strings } from '../assets/common/strings';

export const useStylesLicenseAppointment = makeStyles({
	table: {
		backgroundColor: 'white',
	},
	tableFixedColumnWidth: {
		backgroundColor: 'white',
		tableLayout: 'fixed',
	},
	iconCheck: {
		height: 25,
		width: 25,
		margin: 'auto',
		display: 'block',
		color: themePalette.tertiary_text,
	},
	buttonActions: {
		paddingBottom: '16px',
		margin: '0px 14px',
	},
	activeIcon: {
		color: '#177245',
	},
	pendingIcon: {
		color: '#fada5e',
	},
	terminatedIcon: {
		color: '#c40233',
	},
	textCenter: {
		textAlign: 'center',
	},
	iconPosition: {
		display: 'flex',
	},
	flexRowCenter: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dialog: {
		minWidth: '27%',
	},
	laTile_label: {
		padding: '0 0.5em 0 0',
		textWrap:'nowrap',
	},
	textOverflow : {
		textWrap:"nowrap",
		overflow:"hidden",
		textOverflow:"ellipsis"
	},
	laTile_Heading: {
		fontSize: '1.125rem',
		marginBottom: '5px',
	},
	laTile_details: {
		marginTop: '20px',
	},
	bold_text: {
		fontWeight: 'bold',
	},
	tabRoot: {
		padding: '15px',
		width: '100%',
	},
	lineOfBusinesses: {
		display: 'inline',
	},
	tableCell: {
		border: 'none',
		textAlign: 'center',
	},
	appointmentHeading: {
		fontSize: '1.125rem',
		marginBottom: '5px',
	},
	appointmentSecondaryHeading: {
		padding: '0 0.5em 0 0',
		color: 'rgba(0, 0, 0, 0.54)',
	},
	appointmentItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
});

export const GetLicenseAppointmentStatusIcon = ({ status, showTooltip = true, iconProps = {} }) => {
	const classes = useStylesLicenseAppointment();
	switch (status) {
		case Strings.LicenseStatusIcon.Active:
			return (
				<Tooltip title={showTooltip ? Strings.LicenseStatusIcon.Active : ''} placement="top">
					<DoneIcon className={classes.activeIcon} {...iconProps} />
				</Tooltip>
			);
		case Strings.AppointmentStatusIcon.Appointed:
			return (
				<Tooltip title={showTooltip ? Strings.AppointmentStatusIcon.Appointed : ''} placement="top">
					<DoneIcon className={classes.activeIcon} {...iconProps} />
				</Tooltip>
			);
		// both pending and terminated state are same for License and Appointment so it can be used interchangeably
		case Strings.LicenseStatusIcon.Pending:
			return (
				<Tooltip title={showTooltip ? Strings.LicenseStatusIcon.Pending : ''} placement="top">
					<FiberManualRecordIcon className={classes.pendingIcon} {...iconProps} />
				</Tooltip>
			);
		case Strings.LicenseStatusIcon.Terminated:
			return (
				<Tooltip title={showTooltip ? Strings.LicenseStatusIcon.Terminated : ''} placement="top">
					<ClearOutlinedIcon className={classes.terminatedIcon} {...iconProps} />
				</Tooltip>
			);
		default:
			return null;
	}
};
