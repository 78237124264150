import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { GetAgentLicenses, GetAgentLicensesByState, GetAgentStates, GetAgentLicenseLineOfAuhtorities, GetAgentAppointmentCountByState, GetAgentAppointments, GetAgentAppointmentLineOfBusinesses, GetAgentAppointmentStates } from '../actions/license_appointment_actions';
import { Strings } from '../assets/common/strings';

export interface AgentState {
    State: string;
    IsResident: boolean;
}

export interface AgentLicenseLineOfAuthority {
    LineOfLicenseText: string;
}

export interface AgentAppointmentLineOfBusiness {
    LineOfBusinessText: string;
}

export interface AgentLicense {
    State: string;
    StatusText: string;
    ExpirationDate: Date;
    LineOfLicenseText: string;
    IsPerpetual: boolean;
}

export interface AgentAppointmentCount{
    LineOfBusinessText: string;
    AppointmentCount: number;
}

export interface AgentAppointment {
    LineOfLicenseText: string;
    CarrierName: string;
    State: string;
    StatusText: string;
}

interface filterForm {
    state: string;
    status: string;
}
export interface LicenseForm extends filterForm {
    lineOfAuhtority: string;
}

export interface AppointmentForm extends filterForm {
    lineOfBusiness: string;
}

export interface AgentAppointmentState {
    State: string;
}

export interface LicenseAppointmentState {
    agentStates: AgentState[];
    agentAppointmentStates: AgentAppointmentState[];
    agentLicenses: AgentLicense[];
    agentAppointments: AgentAppointment[];
    agentLicenseLineOfAuthorities: AgentLicenseLineOfAuthority[];
    agentAppointmentLineOfBusinesses: AgentAppointmentLineOfBusiness[];
    agentAppointmentCount: AgentAppointmentCount[];
    loading: boolean;
    filterLicenseForm: LicenseForm;
    filterAppointmentForm: AppointmentForm;
}

export const initialLicenseForm: LicenseForm = {
    state: Strings.LicenseAppointmentFilterLabel.All,
    status: Strings.LicenseAppointmentFilterLabel.All,
    lineOfAuhtority: Strings.LicenseAppointmentFilterLabel.All,
};

export const initialAppointmentForm: AppointmentForm = {
    state: Strings.LicenseAppointmentFilterLabel.All,
    status: Strings.LicenseAppointmentFilterLabel.All,
    lineOfBusiness: Strings.LicenseAppointmentFilterLabel.All,
};

const initialState: LicenseAppointmentState = {
    agentStates: [],
    agentAppointmentStates: [],
    agentLicenses: [],
    agentAppointments: [],
    agentLicenseLineOfAuthorities: [],
    agentAppointmentLineOfBusinesses: [],
    agentAppointmentCount: [],
    loading: false,
    filterLicenseForm: initialLicenseForm,
    filterAppointmentForm: initialAppointmentForm
};

export function licenseAppointmentReducer(
    state: LicenseAppointmentState = initialState,
    action: ReduxAction
): LicenseAppointmentState {

    if (isType(action, GetAgentStates.started) || isType(action, GetAgentLicenses.started) || isType(action, GetAgentLicenseLineOfAuhtorities.started)
        || isType(action, GetAgentAppointments.started) || isType(action, GetAgentAppointmentLineOfBusinesses.started) || isType(action, GetAgentAppointmentStates.started)) {
        return {
            ...state,
            loading: true
        }
    } else if (isType(action, GetAgentStates.done)) {
        return {
            ...state,
            agentStates: action.payload.result,
            loading: false
        }
    } else if (isType(action, GetAgentLicenses.done)) {
        return {
            ...state,
            agentLicenses: action.payload.result,
            loading: false
        }
    } else if (isType(action, GetAgentLicensesByState.done)) {
        return {
            ...state,
            agentLicenses: action.payload.result,
        }
    } else if (isType(action, GetAgentLicenseLineOfAuhtorities.done)) {
        return {
            ...state,
            agentLicenseLineOfAuthorities: action.payload.result,
        }
    } else if (isType(action, GetAgentAppointmentCountByState.done)) {
    return {
        ...state,
        agentAppointmentCount: action.payload.result,
        }
    }
    else if (isType(action, GetAgentAppointments.done)) {
        return {
            ...state,
            agentAppointments: action.payload.result,
            loading: false
        }
    }
    else if (isType(action, GetAgentAppointmentLineOfBusinesses.done)) {
        return {
            ...state,
            agentAppointmentLineOfBusinesses: action.payload.result,
        }
    }
    else if (isType(action, GetAgentAppointmentStates.done)) {
        return {
            ...state,
            agentAppointmentStates: action.payload.result,
        }
    }
    else {
        return state;
    }
}