
import { submitLog } from '../logging_util';
import { store } from '../..';
import { ReceivedFcmNotification } from '../../actions/fcm_actions';
import { Strings } from '../../assets/common/strings';
import { isBrokerage } from '../brokerage_utils';
import { AppConfig } from '../../types/config';
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from "firebase/messaging"

const acIcon180 = require('../../assets/pwa/ac-apple-icon-180.png');
const bcIcon180 = require('../../assets/pwa/bc-apple-icon-180.png');

declare const firebase: any;
export interface FcmNotification {
    data: {
        url: string;
        type: string;
        id: string;
    },
    notification: {
        title: string;
        body: string;
    }
}

export const registerFireBase: () => Promise<string> = async () => {


    const firebaseConfig = {
        apiKey: AppConfig.firebaseApiKey,
        authDomain: "aaap-model.firebaseapp.com",
        databaseURL: "https://aaap-model.firebaseio.com",
        projectId: "aaap-model",
        storageBucket: "aaap-model.appspot.com",
        messagingSenderId: "316003629206",
        appId: AppConfig.firebaseAppId
    };

    const app = initializeApp(firebaseConfig);

    const messaging = getMessaging(app);

    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
        const token = getToken(messaging, { vapidKey: AppConfig.firebaseVapidKey })
            .then((currentToken: string) => {
                submitLog(Strings.Log.Log, `Push notification permission granted by the user. Token: ${currentToken}`, { currentToken });
                return currentToken;
            });
    } else if (permission === "denied") {
        submitLog(Strings.Log.Info, `Push notification permission denied by the user.`);
    }

    return Promise.reject("Permission denied");
};