export interface ThemePalette {
	//mui
	primary: string;
	secondary: string;
	error: string;

	//items 
	selected_item: string;
	preferred_item: string;
	unselected_item: string;

	//avatars
	negative_avatar: string;
	default_avatar: string;
	agent_avatar: string;
	customer_avatar: string; 
	tools_avatar: string;
	lob_avatar: string;
	lead_avatar: string; 
	selected_avatar: string;
	urgent_avatar: string;

	//snackbars 
	success_snackbar: string;
	failure_snackbar: string;
	info_snackbar: string;

	//icons 
	negative_icon: string; 
	disabled_icon: string;
	action_icon: string;
	disabled_action_icon: string; 
	//buttons
	accept_button: string;
	disabled_fab: string;
	disabled_button: string;
	fab_menu_button: string;
	delete_remove_reject_button: string;
	import_button: string; 
	phone_button: string; 

	//text
	negative_text: string;
	required_text: string;
	sub_text: string;
	primary_text: string;
	tertiary_text: string;
	address_fields_text: string; 
	battle_won: string;
	battle_lost: string;
	battle_tied: string;
	battle_current: string;
	battle_upcoming: string;
	battle_requested: string;

	//backgrounds
	default_background: string;
	header_bar: string;
	login_background: string;
	progress_bar: string;
	notification: string;
	secondary_background: string;
	persistent_notification_background: string; 
	pin_lock_background: string; 
	bubbles_primary: string;
	bubbles_secondary: string;
	bubbles_tertiary: string;
	battle_background: string;
	battle_banner_background: string;
	battle_power_bar: string;
	battle_rank: string;
	battle_rank_inner: string;
	battle_backdrop: string;
	ytdav_graph_header: string;

	//pills
	notification_recipient: string;
	deleted_notification: string;
	clientconnect_activity: string;

	//misc
	loaded: string; 
	selected_overlay: string;
	notification_count: string;
	expandable_item_bubble: string;
	divider: string;
	active_time_bar: string;
	inactive_time_bar: string;
	favorite: string;
	unfavorite: string;
	national_dnc: string;
	company_dnc: string;
	unsupported_browser: string;
	battles_user: string;
	battles_opponent: string;
	chart_infinity: string;
	milestone_completed: string;
	milestone_missed: string;
	milestone_earnings: string;
	login_loading: string;
	up_arrow: string;
	down_arrow: string;

	//svg
	defaultSvg: string;
	menuSvgNotifications: string;
	menuSvgActivities: string;
	menuSvgCRM: string;
	menuSvgFactFinder: string;
	menuSvgLeads: string;
    menuSvgClientConnect: string;
    menuSvgTeamView: string;
	menuSvgPerformance: string;
	menuSvgLicenseAppointment: string;
	menuSvgBattles: string;
	menuSvgContests: string;
	menuSvgFinance: string;
	menuSvgTraining: string;
	menuSvgDocuments: string;
	menuSvgTools: string;
	menuSvgAnnouncments: string;
	menuSvgProfile: string;
	menuSvgPodcast: string;
	menuSvgSettings: string;
	menuSvgQuotes: string;
	menuSvgConnecture: string;
}

export const themePalette: ThemePalette = {
	//mui
	primary: '#009FDA',
	secondary: '#69BE28',
	error: '#CA005D',

	//items 
	selected_item: '#69BE28',
	preferred_item: '#69BE28',
	unselected_item: '#8D8D8D',

	//avatars
	negative_avatar: '#FFFFFF',
	default_avatar: '#8D8D8D',
	agent_avatar: '#0065BD',
	customer_avatar: '#0065BD',
	tools_avatar: '#0065BD',
	lob_avatar: '#8D8D8D',
	lead_avatar: '#8D8D8D',
	selected_avatar: '#69BE28',
	urgent_avatar: '#69BE28',

	//snackbars 
	success_snackbar: '#69BE28',
	failure_snackbar: '#E37222',
	info_snackbar: '#000000',

	//icons 
	negative_icon: '#FFFFFF',
	disabled_icon: '#D6D6D6',
	action_icon: '#69BE28',
	disabled_action_icon: '#4D4D4D',

	//buttons
	accept_button: '#69BE28',
	disabled_fab: '#D6D6D6',
	disabled_button: '#D6D6D6',
	fab_menu_button: '#57068C',
	delete_remove_reject_button: '#F5802D',
	import_button: '#009FDA',
	phone_button: '#69BE28',

	//text
	negative_text: '#FFFFFF',
	required_text: '#CA005D',
	sub_text: '#4D4D4D',
	primary_text: '#000000',
	tertiary_text: '#8D8D8D',
	address_fields_text: '#69BE28',
	battle_won: '#69BE28',
	battle_lost: '#CA005D',
	battle_tied: '#303030',
	battle_current: '#009FDA',
	battle_upcoming: '#303030',
	battle_requested: '#F5802D',

	//backgrounds
	default_background: '#FFFFFF',
	header_bar: '#009FDA',
	login_background: '#009FDA',
	notification: '#009FDA',
	progress_bar: '#69BE28',
	secondary_background: '#F6F4F2',
	persistent_notification_background: '#B9D8F1',
	pin_lock_background: '#4D4D4D',
	bubbles_primary: '#69BE28',
	bubbles_secondary: '#009FDA',
	bubbles_tertiary: '#F5802D',
	battle_background: '#0065BD',
	battle_banner_background: '#0098d3',
	battle_power_bar: '#69BE28',
	battle_rank: '#0065BD',
	battle_rank_inner: '#009FDA',
	battle_backdrop: '#FFFFFF',
	ytdav_graph_header: '#009FDA',

	//pills
	notification_recipient: '#009FDA',
	deleted_notification: '#F5802D',
	clientconnect_activity: '#69BE28',

	//misc
	loaded: '#69BE28',
	selected_overlay: '#E8F5Fb',
	notification_count: '#69BE28',
	expandable_item_bubble: '#8D8D8D',
	divider: '#8D8D8D',
	active_time_bar: '#F5802D',
	inactive_time_bar: '#8D8D8D',
	favorite: '#F5802D',
	unfavorite: '#8D8D8D',
	national_dnc: '#F5802D',
	company_dnc: '#CA005D',
	unsupported_browser: '#F5802D',
	battles_user: '#009FDA',
	battles_opponent: '#69BE28',
	chart_infinity: '#FF0000',
	milestone_completed: '#69BE28',
	milestone_missed: '#CA005D',
	milestone_earnings: '#009FDA',
	login_loading: '#0065BD',
	up_arrow: '#69BE28',
	down_arrow: '#CA005D',

	//svgs
	defaultSvg: '#4D4D4D',
	menuSvgNotifications: '#009FDA',
	menuSvgActivities: '#69BE28',
	menuSvgCRM: '#009FDA',
	menuSvgLeads: '#F5802D',
	menuSvgFactFinder: '#69BE28',
    menuSvgClientConnect: '#009FDA',
    menuSvgTeamView: '#009FDA',
	menuSvgPerformance: '#009FDA',
	menuSvgLicenseAppointment: '#F5802D',
	menuSvgBattles: '#009FDA',
	menuSvgContests: '#F5802D',
	menuSvgFinance: '#69BE28',
	menuSvgTraining: '#F5802D',
	menuSvgDocuments: '#69BE28',
	menuSvgTools: '#F5802D',
	menuSvgAnnouncments: '#69BE28',
	menuSvgProfile: '#009FDA',
	menuSvgPodcast: '#F5802D',
	menuSvgSettings: '#F5802D',
	menuSvgQuotes: '#69BE28',
	menuSvgConnecture: '#F5802D',
}
