type Configs = {
    agentconnect_ecom_link: string;
    azure_geo_search_key: string;
    azure_geo_search_url: string;
    banking_info_link: string;
    benefitter_link: string;
    broker_web_api: string;
    bypass_permissions: boolean;
    client_connect_link: string;
    environment: string;
    excelsior_broker_docs_link: string;
    finance_link: string;
    firebaseApiKey: string;
    firebaseAppId: string;
    firebaseVapidKey: string;
    forgot_password_link: string;
    ga_id: string;
    google_api_key: string;
    google_calendar_code_link: string;
    google_calendar_code_scope: string;
    google_client_id: string;
    google_poll_request_grant_type: string;
    google_poll_request_link: string;
    help_support_link: string;
    is_license_appointment_feature_flag_enabled: boolean;
    lead_connect_link: string;
    lead_contribution_link: string;
    my_profile_link: string;
    reset_password_link: string;
    rise_registry_url: string;
    tinymce_api_key: string;
    verbosity_level: string;
    web_api: string;
};

export type AppConfiguration = Configs & ProtectedConfigs;

export type ProtectedConfigs = {};

export let AppConfig = {} as Readonly<AppConfiguration>;

export const loadAppConfiguration = async () => {
    const response = await fetch('/config.json');
    if (response.ok) {
        const data: Readonly<AppConfiguration> = await response.json();
        setAppConfiguration(data);

        return data;
    }

    return {};
};

export const setAppConfiguration = (config: Configs | ProtectedConfigs) => {
    AppConfig = Object.assign({}, AppConfig, config);
};
